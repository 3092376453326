import $ from "../../libs/jquery-3.5.1.min.js";
import {gsap} from "../../libs/gsap/all.js";

import {ExerciceBase} from "../ExerciceBase";

// ======================================================
// Faites une copie de cette base et ajouter votre nom à la suite (ex: Exercice1_Marc1
// N'oubliez pas d'aller charger votre fichier dans le fichier js/index.js
// ======================================================

export class Exercice1_Caro extends ExerciceBase {

  constructor() {
    console.log("Exercice 1 - Caro")
    super();

    // ======================================================
    // Vous pouvez changer ce que vous voulez après cette ligne
    // ======================================================

    this.initScrollEvents();
    this.initAnimations();
  }

  initScrollEvents() {

  }

  initAnimations() {

  }

}


