import $ from "../../libs/jquery-3.5.1.min.js";
import {gsap} from "../../libs/gsap/all.js";

import {ExerciceBase} from "../ExerciceBase";

/*

 NOTE:

 Ne pas regarder le code de mon exemple :)

 Le but est de le reproduire par vous même le plus possible.
 Soyez original!




















































 */

export class Exercice1_Marc1 extends ExerciceBase {

  constructor() {
    super();

    console.log("Exercice 1");
    this.initScrollEvents();
    this.initAnimations();
  }

  initScrollEvents() {
    this.scroll.on('scroll', (args) => {
      if (typeof args.currentElements['section-2-splitted-text'] === 'object') {
        let progress = args.currentElements['section-2-splitted-text'].progress;
        this.tlSection2SplittedText.progress(progress);
      }
    });
  }

  initAnimations() {

    this.tlSection2SplittedText = gsap.timeline({paused: true});

    gsap.set($("#section-2 .splitted-text > *"), {perspective: 400});

    this.tlSection2SplittedText.from($("#section-2 .splitted-text > *"), {
      duration: 0.8,
      opacity: 0,
      scale: 0,
      y: 80,
      rotationX: 180,
      transformOrigin: "0% 50% -50",
      ease: "back",
      stagger: 0.1,
      // paused: true
    });
  }

}


