import $ from "../libs/jquery-3.5.1.min.js";
import {gsap} from "../libs/gsap/gsap-core.js";



export class MainApp {


  constructor() {

  }

  initTriggers() {

  }

  initScroll() {

  }

}


