import './helpers.js';

import {MainApp} from './app/MainApp.js'
window.MainApp = MainApp;

import {Exercice1_Base} from './app/Exercice1/Exercice1_Base.js'
window.Exercice1_Base = Exercice1_Base;

import {Exercice1_Marc1} from './app/Exercice1/Exercice1_Marc1.js'
window.Exercice1_Marc1 = Exercice1_Marc1;

import {Exercice1_Daphnee} from './app/Exercice1/Exercice1_Daphnee.js'
window.Exercice1_Daphnee = Exercice1_Daphnee;

import {Exercice1_Caro} from './app/Exercice1/Exercice1_Caro.js'
window.Exercice1_Caro = Exercice1_Caro;

import {Exercice1_Marc2} from './app/Exercice1/Exercice1_Marc2.js'
window.Exercice1_Marc2 = Exercice1_Marc2;
