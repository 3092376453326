import $ from "../../libs/jquery-3.5.1.min.js";
import {gsap} from "../../libs/gsap/all.js";

import {ExerciceBase} from "../ExerciceBase";

// ======================================================
// Faites une copie de cette base et ajouter votre nom à la suite (ex: Exercice1_Marc1
// N'oubliez pas d'aller charger votre fichier dans le fichier js/index.js
// ======================================================

export class Exercice1_Daphnee extends ExerciceBase {

  constructor() {
    console.log("Exercice 1 - Daphnee")
    super();

    this.initScrollEvents();
    this.initAnimations();
  }

  initScrollEvents() {
    this.scroll.on('scroll', (args) => {
      // Get all current elements : args.currentElements
      if(typeof args.currentElements['first'] === 'object') {
        let progress = args.currentElements['first'].progress;
        console.log(progress);
        // gsap example : myGsapAnimation.progress(progress);
        this.animationFirst.progress(progress);
      }

      if(typeof args.currentElements['fromLeft'] === 'object') {
        let progress = args.currentElements['fromLeft'].progress;
        this.animationLeft.progress(progress);
      }

      if(typeof args.currentElements['fromRight'] === 'object') {
        let progress = args.currentElements['fromRight'].progress;
        this.animationRight.progress(progress);
      }

      if(typeof args.currentElements['background'] === 'object') {
        let progress = args.currentElements['background'].progress;
        this.animationBg.progress(progress);
      }

      if(typeof args.currentElements['square-1'] === 'object') {
        let progress = args.currentElements['square-1'].progress;
        this.square1.progress(progress);
      }

      if(typeof args.currentElements['square-2'] === 'object') {
        let progress = args.currentElements['square-2'].progress;
        this.square2.progress(progress);
      }

      if(typeof args.currentElements['color'] === 'object') {
        let progress = args.currentElements['color'].progress;
        this.color.progress(progress);
      }
    });
  }

  initAnimations() {
    this.animationFirst = gsap.timeline({paused:true});
    this.animationLeft = gsap.timeline({paused:true});
    this.animationRight = gsap.timeline({paused:true});
    this.animationBg = gsap.timeline({paused:true});

    this.square1 = gsap.timeline({paused:true});
    this.square2 = gsap.timeline({paused:true});
    this.color = gsap.timeline({paused:true});

    this.animationFirst.from($('.splitted-text .container-left .first'),{
      opacity: 0,
      ease: "back",
      duration: 0.5,
      rotationX: 90,
      y: '100%',
      delay: 0.1
    })

    this.animationLeft.from($('.splitted-text .container-left .left'),{
      x: '-100%',
      opacity: 0,
      ease: "power1",
      duration: 0.1,
    })

    this.animationRight.from($('.splitted-text .container-left .right'),{
      x: '100%',
      opacity: 0,
      ease: "power1",
      duration: 0.1,
    })

    this.animationBg.from($('.splitted-text .container-left .background'),{
      x: '500px',
      ease: "power1"
    })

    this.square1.from($('.splitted-text .container-right .square1'),{
      width: 0,
      height: 0,
      borderWidth: 50,
      ease: "power1"
    })

    this.square2.from($('.splitted-text .container-right .square2'),{
     rotation: '270deg',
      ease: "power1"
    })

    this.color.from($('#section-5'),{
      backgroundPosition: "-1000px -1000px",
      ease: "power1"
    })
  }

}


