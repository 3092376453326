/*
 * Get the real viewport width including the sidebars. This is necessary
 * to match the real media query because $(window).innerWidth() is off by 15px.
 * Usage: var vpWidth = window.viewport().width;
 */
window.viewport = function () {
  var e = window, a = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  return {width: e[a + 'Width'], height: e[a + 'Height']};
}

window.constrain = function (aNumber, aMin, aMax) {
  return aNumber > aMax ? aMax : aNumber < aMin ? aMin : aNumber;
};

window.map = function (value, istart, istop, ostart, ostop) {
  return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
};

window.randomInRange = function (map, prop) {
  var min = map[prop + "Min"],
    max = map[prop + "Max"];
  return min + (max - min) * Math.random();
}

window.getRandomInt = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

window.getRandomFloat = function (min, max) {
  return Math.random() * (max - min + 1) + min;
}

window.getURLParameter = function (name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
};

window.millis = function () {
  return Date.now();
}

window.dist = function (x1, y1, x2, y2) {
  return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));
}

window.timeAmPm = function (hours, minutes) {

  var v = (hours % 12) == 0 ? 12 : (hours % 12);

  if (minutes !== false) {
    v += ":";
    v += minutes.toString().length > 1 ? minutes : '0' + minutes;
  }

  if (hours < 12) {
    v += " AM";
  } else {
    v += " PM";
  }

  return v;
}

window.doubleTimeAmPm = function (hours1, minutes1, hours2, minutes2) {
  var time1 = timeAmPm(hours1, minutes1);
  var time2 = timeAmPm(hours2, minutes2);

  if ((time1.indexOf("AM") >= 0) && (time2.indexOf("AM") >= 0)) {
    time1 = time1.replace("AM", "");
  }

  if ((time1.indexOf("PM") >= 0) && (time2.indexOf("PM") >= 0)) {
    time1 = time1.replace("PM", "");
  }

  return time1 + " - " + time2;
}


window.throttle = function (fn, wait, recallOnLast = false) {
  var time = Date.now();
  var callback = false;

  return function () {
    if ((time + wait - Date.now()) < 0) {
      fn();
      time = Date.now();

      if (recallOnLast) {
        clearTimeout(callback);
        callback = setTimeout(function () {
          fn();
        }, wait + 1);
      }
    }
  }
}


/*!
 * Behaves the same as setInterval except uses requestAnimationFrame() where possible for better performance
 * modified gist.github.com/joelambert/1002116
 * the fallback function requestAnimFrame is incorporated
 * gist.github.com/joelambert/1002116
 * gist.github.com/englishextra/873c8f78bfda7cafc905f48a963df07b
 * jsfiddle.net/englishextra/sxrzktkz/
 * @param {Object} fn The callback function
 * @param {Int} delay The delay in milliseconds
 * requestInterval(fn, delay);
 */
window.requestInterval = function (fn, delay) {
  var requestAnimFrame = (function () {
      return window.requestAnimationFrame || function (callback, element) {
        window.setTimeout(callback, 1000 / 60);
      };
    })(),
    start = new Date().getTime(),
    handle = {};

  function loop() {
    handle.value = requestAnimFrame(loop);
    var current = new Date().getTime(),
      delta = current - start;
    if (delta >= delay) {
      fn.call();
      start = new Date().getTime();
    }
  }

  handle.value = requestAnimFrame(loop);
  return handle;
};

/*!
 * Behaves the same as clearInterval except uses cancelRequestAnimationFrame()
 * where possible for better performance
 * gist.github.com/joelambert/1002116
 * gist.github.com/englishextra/873c8f78bfda7cafc905f48a963df07b
 * jsfiddle.net/englishextra/sxrzktkz/
 * @param {Int|Object} handle function handle, or function
 * clearRequestInterval(handle);
 */
window.clearRequestInterval = function (handle) {
  if (window.cancelAnimationFrame) {
    window.cancelAnimationFrame(handle.value);
  } else {
    window.clearInterval(handle);
  }
};
/*!
 * Behaves the same as setTimeout except uses requestAnimationFrame()
 * where possible for better performance
 * modified gist.github.com/joelambert/1002116
 * the fallback function requestAnimFrame is incorporated
 * gist.github.com/joelambert/1002116
 * gist.github.com/englishextra/873c8f78bfda7cafc905f48a963df07b
 * jsfiddle.net/englishextra/dnyomc4j/
 * @param {Object} fn The callback function
 * @param {Int} delay The delay in milliseconds
 * requestTimeout(fn,delay);
 */
window.requestTimeout = function (fn, delay) {
  var requestAnimFrame = (function () {
      return window.requestAnimationFrame || function (callback, element) {
        window.setTimeout(callback, 1000 / 60);
      };
    })(),
    start = new Date().getTime(),
    handle = {};

  function loop() {
    var current = new Date().getTime(),
      delta = current - start;
    if (delta >= delay) {
      fn.call();
    } else {
      handle.value = requestAnimFrame(loop);
    }
  }

  handle.value = requestAnimFrame(loop);
  return handle;
};
/*!
 * Behaves the same as clearTimeout except uses cancelRequestAnimationFrame()
 * where possible for better performance
 * gist.github.com/joelambert/1002116
 * gist.github.com/englishextra/873c8f78bfda7cafc905f48a963df07b
 * jsfiddle.net/englishextra/dnyomc4j/
 * @param {Int|Object} handle The callback function
 * clearRequestTimeout(handle);
 */
window.clearRequestTimeout = function (handle) {
  if (window.cancelAnimationFrame) {
    window.cancelAnimationFrame(handle.value);
  } else {
    window.clearTimeout(handle);
  }

};

/// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
// Set the name of the hidden property and the change event for visibility
var hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}
