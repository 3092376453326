import $ from "../../libs/jquery-3.5.1.min.js";
import {gsap} from "../../libs/gsap/all.js";

import {ExerciceBase} from "../ExerciceBase";

// ======================================================
// Faites une copie de cette base et ajouter votre nom à la suite (ex: Exercice1_Marc1
// N'oubliez pas d'aller charger votre fichier dans le fichier js/index.js
// ======================================================

export class Exercice1_Marc2 extends ExerciceBase {

  constructor() {
    console.log("Exercice 1 - Marc2")
    super();

    this.initScrollEvents();
    this.initAnimations();
  }

  initScrollEvents() {
    this.scroll.on('scroll', (args) => {
      if (typeof args.currentElements["circle-left"] === "object") {
        let progress = args.currentElements['circle-left'].progress;
        this.animationCircleLeft.progress(progress);
      }
      if (typeof args.currentElements["circle-right"] === "object") {
        let progress = args.currentElements["circle-right"].progress;
        this.animationCircleRight.progress(progress);
      }
      if (typeof args.currentElements["stagger-lines"] === "object") {
        let progress = args.currentElements["stagger-lines"].progress;
        this.animationStaggerLines.progress(progress);
      }
      if (typeof args.currentElements["stagger-square"] === "object") {
        let progress = args.currentElements["stagger-square"].progress;
        this.animationStaggerLines.progress(progress);
      }
      if (typeof args.currentElements["square-rotation"] === "object") {
        let progress = args.currentElements["square-rotation"].progress;
        this.animationSquareRotation.progress(progress);
      }
      if (typeof args.currentElements["background-move"] === "object") {
        let progress = args.currentElements["background-move"].progress;
        this.animationBackgroundMove.progress(progress);
      }
    });
  }

  initAnimations() {
    this.animationCircleLeft = gsap.timeline({paused:true});
    this.animationCircleRight = gsap.timeline({paused:true});
    this.animationStaggerLines = gsap.timeline({paused:true});
    this.animationSquareRotation = gsap.timeline({paused:true});
    this.animationBackgroundMove = gsap.timeline({paused:true});

    this.animationCircleLeft.to($("#section-1 .circle-left"), {
      clipPath: "circle(100% at 0 0)",
    });
    this.animationCircleRight.to($("#section-1 .circle-right"), {
      clipPath: "circle(100% at 100% 100%)",
    });
    this.animationStaggerLines.fromTo($("#section-2 .splitted-text h2"), {opacity: 0, y: -100, rotation: -45}, {
      opacity: 1,
      rotation: 0,
      y: 0,
      stagger: 0.15,
    });
    this.animationSquareRotation.to($("#section-2 .square-rotation"), {rotation: 180});

    this.animationBackgroundMove.to($("#section-5 .background > div"), {x: 150});
  }

}


