import $ from "../libs/jquery-3.5.1.min.js";
import LocomotiveScroll from "../libs/locomotive-scroll.min";


export class ExerciceBase {

  constructor() {
    console.log("ExerciceBase")
    this.initTriggers();
    this.initScroll();
  }

  initTriggers() {
    // Enable anchors scrollTo
    $('a[href^="#"]').on("click", function (e) {
      e.preventDefault();
      console.log(this);

      let target = $(e.target).attr("href");
      if ($(target).length) {
        this.scroll.scrollTo(target);
      }
    }.bind(this));
  }

  initScroll() {
    // Enable smoothscroll
    this.scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      touchMultiplier: 2.75,
      reloadOnContextChange: true,
      smartphone: {
        smooth: true
      },
      tablet: {
        smooth: true,
      },
    });

  }

}


